.singlePostTitleInput {
  width: 250px;
  margin: 10px;
  font-family: "Lora", serif;
  font-size: 28px;
  text-align: center;
  border: none;
  color: gray;
  border-bottom: 1px solid lightgray;
  margin: 0;
}

div.DraftEditor-root {
  border: 1px solid #000;
  background-color: rgb(240, 240, 239);
  height: 300px;
  width: 100%;
  overflow-y: auto;
  margin:0 0;
}
div.DraftEditor-editorContainer,
div.public-DraftEditor-content {
  height: 100%;
 
}


.cancelButton{
    margin-bottom: 5px;
}

.deleteButton{
    margin-bottom: 5px;
}

.ShortDescInput{
    height: 4em;
    width: 15em;
}

.TitleInput{
    width: 15em;
}

.PriceCategoryInput{
    width: 15em;
}

.MetaDescInput{
    height: 4em;
    width: 15em;
}

.ImageInput{
    width: 15em;
}

.ThumbnailInput{
    width: 15em;
}


@media only screen and (min-width: 760px) {
    .ShortDescInput{
        height: 4em;
        width: 28em;
    }

    .TitleInput{
        width: 28em;
    }
    
    .PriceCategoryInput{
        width: 28em;
    }
    
    .MetaDescInput{
        height: 4em;
        width: 28em;
    }
    
    .ImageInput{
        width: 28em;
    }
    
    .ThumbnailInput{
        width: 28em;
    }

  }

  @media only screen and (min-width: 1020px) {
    .ShortDescInput{
        height: 4em;
        width: 40em;
    }

    .TitleInput{
        width: 40em;
    }
    
    .PriceCategoryInput{
        width: 40em;
    }
    
    .MetaDescInput{
        height: 4em;
        width: 40em;
    }
    
    .ImageInput{
        width: 40em;
    }
    
    .ThumbnailInput{
        width: 25em;
    }
  }

  @media only screen and (min-width: 1400px) {
    .ShortDescInput{
        height: 4em;
        width: 100em;
    }

    .TitleInput{
        width: 100em;
    }
    
    .PriceCategoryInput{
        width: 100em;
    }
    
    .MetaDescInput{
        height: 4em;
        width: 100em;
    }
    
    .ImageInput{
        width: 100em;
    }
    
    .ThumbnailInput{
        width: 25em;
    }
  }