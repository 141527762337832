/* .box {
  padding-top: 10px;
} */

h1 {
  color: #4ac9ed;
}

.icons {
  padding-right: 10px;
  color: #628be2;
}

ul {
  color: rgb(104, 104, 104);
  list-style-type: none;
}
p {
  color: rgb(104, 104, 104);
}

.noDecoration {
  list-style: none;
}
