input {
  margin-bottom: 10px;
  background-color: whitesmoke;
}
input:focus {
  outline: none;
}

textarea {
  margin-bottom: 10px;
  background-color: whitesmoke;
  max-width: 600px;
}

textarea:focus {
  outline: none;
}

.admin-h1 {
  color: #060b26;
  margin-left: 200px;
  font-size: 1.7rem;
  padding-bottom: 25px;
}

.admin-h2 {
  color: #060b26;
  font-size: 1.4rem;
}

.cancelButton {
  float: right;
  margin-right: 15px;
  background-color: #585858;
  border: none;
  padding: 15px 32px;
  font-size: 1.2rem;
  color: white;
  font-weight: 500;
}

.createButton {
  float: right;
  margin-right: 15px;
  background-color: #008cba;
  border: none;
  padding: 15px 32px;
  font-size: 1.2rem;
  color: white;
  font-weight: 500;
}

.updateButton {
  float: right;
  margin-right: 15px;
  background-color: #008cba;
  border: none;
  padding: 15px 32px;
  font-size: 1.2rem;
  color: white;
  font-weight: 500;
}

.deleteButton {
  float: right;
  margin-right: 15px;
  background-color: #ff0000;
  border: none;
  padding: 15px 32px;
  font-size: 1.2rem;
  color: white;
  font-weight: 500;
}

.newButton {
  background-color: #008cba;
  width: 200px;
  color: white;
  border: none;
  cursor: pointer;
  padding: 2px 12px 3px 12px;
  text-decoration: none;
  text-align: center;
}

.newButton:hover {
  background-color: #060b26;
}

.createNew-div {
  display: block;
}

.createNew-div .newButton {
  float: right;
}

/* .list-span {
  text-decoration: none;
  font-size: 1.2rem;
  width: auto;
  border: none;
  cursor: pointer;
  padding: 2px 12px 3px 12px;
  margin-bottom: 15px;
}

.list-span:hover {
  background-color: #060b26;
  color: white;
} */


.list-span {
  font-size: 18px;
    font-weight: 500;
cursor: pointer;
    color: rgb(59, 59, 59);
}

.list-span:hover {
  color: #0276b9;
}

.links__admin-all{
  text-decoration: none;
    transition: all .2s ease-in-out;
}

.links__admin-all:hover{
  transform: scale(1.1);
    transform: scale(1.1);
}


