.heroBlock {
  background: url("bg-hero.jpg") no-repeat;
  height: 500px;
  width: auto;
  position: relative;
  background-size: cover;
  background-position: 50% 50%;
}
@media only screen and (max-width: 457px) {
  .heroBlock {
    height: 200px;
  }
}

.heroBlock .container-fluid {
  display: block !important;
}

.heroBlock .ant-carousel,
.heroBlock .ant-carousel div {
  height: 100%;
}

.heroBlock .slick-dots li button {
  background: #000 !important;
}

.heroBlock .slick-dots li.slick-active button {
  background: #1890ff !important;
}

.heroBlock .content {
  /* max-width: 640px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0 70px;
  vertical-align: bottom;
}

.heroBlock h3 {
  font-size: 30px;
  line-height: 1.2;
  /* margin: 0 0 30px; */
  margin-left: 50px;
}

.heroBlock p {
  margin: 0 0 30px;
}

.heroBlock .btnHolder {
  height: auto !important;
}

.heroBlock .btnHolder button,
.heroBlock .btnHolder .fas {
  margin: 0 10px 0 0;
}
