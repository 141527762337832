/* 
.cardss {
  margin: auto;
  width: auto;
  max-width: 1080px;
  display: grid;


  grid-auto-rows: auto;
  gap: 20px;
  font-family: sans-serif;
  padding-top: 30px;
  cursor: pointer;
}

.cardss * {
  box-sizing: border-box;
}

.cardd__image {
  width: auto;
  height: 150px;
  object-fit: cover;
  display: block;
  padding-right: 20px;
  margin-bottom: 15px;
}

.cardd__content {
  line-height: 1.5;
  font-size: 0.9em;
  padding: 15px;
  background: #ffffff;
  margin: auto;
}

.cardd__content > p {
  margin-top: auto;
  padding-left: 15px;
  width: 30px;
  max-width: calc(100% - 20px);
  margin: 0 auto;
  padding: 0 10px;
}

.cardd__info {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #555555;
  background: #eeeeee;
  font-size: 0.8em;
}

.cardd__info i {
  font-size: 0.9em;
  margin-right: 8px;
}

.cardd__link {
  color: #64968c;
  text-decoration: none;
}

.cardd__link:hover {
  text-decoration: underline;
}
.cardd {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardd-title {
  color: #3da3f5;
  font-size: 1.3em;
}

@media only screen and (max-width: 1024px) {
  .cardd {
    display: block;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (max-width: 1440px) {
  .cardd {
    display: block;
    justify-content: center;
    align-items: center;
  }
}
@media only screen and (max-width: 2560px) {
  .cardd {
    display: block;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (min-width: 1024px) {
  .cardd__image {
    width: auto;
    height: 150px;
  }
}

@media only screen and (min-width: 320px) {
  .cardd__image {
    width: 320px;
    height: auto;
  }
}

@media only screen and (min-width: 425px) {
  .cardd__image {
    width: 425px;
    height: auto;
  }
} */

.cardd {
  display:block;
  cursor: pointer;
  width:auto;
  max-width:1080px;
}

  .cardd__image {
    width: 100%;
    height: auto;
  }

  .innerLeft {
    display:inline-block;
    margin-right:-4px;
    vertical-align:top;
    width:30%;
    text-align:center;
    padding:5px;
  }
  .innerRight {
    display:inline-block;
    vertical-align:top;
    width:70%;
    text-align:left;
    padding:5px;
  }
  .card-text {
    display:inline-block;
  }

  .cardd-title {
    color: #3da3f5;
    font-size: 1.3em;
  }

 

  @media only screen and (max-width: 980px) {
    .cardd {
      display: block;
      justify-content: center;
      align-items: center;
    }
    .innerRight {
      display:block;
      width:auto;
      
    }
    .innerLeft{
      width: auto;
    }
    .cardd__image {
      width: 100%;
      height: auto;
    }
  }
  @media only screen and (max-width: 1024px) {
    .cardd__image {
      width: 205px;
      height: auto;
    }
  }

  @media only screen and (max-width: 768px) {
    .cardd__image {
      width: 100%;
      height: auto;
    }
  }
  @media only screen and (max-width: 425px) {
    .cardd__image {
      width: 100%;
      max-width: 405px;
      height: auto;
    }
  }
  @media only screen and (max-width: 320px) {
    .cardd__image {
      width: 100%;
      max-width: 300px;
      height: auto;
    }
  }
  