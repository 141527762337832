.singlePostTitleInput {
    width: 250px;
    margin: 10px;
    font-family: "Lora", serif;
    font-size: 28px;
    text-align: center;
    border: none;
    color: gray;
    border-bottom: 1px solid lightgray;
  }
  
  div.DraftEditor-root {
    border: 1px solid #000;
    background-color: rgb(240, 240, 239);
    height: 300px;
    width: 100%;
    overflow-y: auto;

  }
  div.DraftEditor-editorContainer,
  div.public-DraftEditor-content {
    height: 100%;
  }




.ShortDescInput{
    height: 4em;
    width: 15em;
}

.TitleInput{
    width: 15em;
}

.PriceCategoryInput{
    width: 15em;
}

.MetaDescInput{
    height: 4em;
    width: 15em;
}

.ImageInput{
    width: 15em;
}

.ThumbnailInput{
    width: 15em;
}
.cancelButton{
    margin-bottom: 5px;
}

.pricecategory{
    width: 15em;
}

@media only screen and (min-width: 760px) {
    .ShortDescInput{
        height: 4em;
        width: 28em;
    }

    .TitleInput{
        width: 28em;
    }
    
    .PriceCategoryInput{
        width: 28em;
    }
    
    .MetaDescInput{
        height: 4em;
        width: 28em;
    }
    
    .ImageInput{
        width: 18em;
    }
    
    .ThumbnailInput{
        width: 18em;
    }
    .pricecategory{
        width: 25em;
    }
  }

  @media only screen and (min-width: 1020px) {
    .ShortDescInput{
        height: 4em;
        width: 40em;
    }

    .TitleInput{
        width: 40em;
    }
    
    .PriceCategoryInput{
        width: 40em;
    }
    
    .MetaDescInput{
        height: 4em;
        width: 40em;
    }
    
    .ImageInput{
        width: 20em;
    }
    
    .ThumbnailInput{
        width: 20em;
    }
    .pricecategory{
        width: 25em;
    }
  }

  @media only screen and (min-width: 1400px) {
    .ShortDescInput{
        height: 4em;
        width: 40em;
    }

    .TitleInput{
        width: 40em;
    }
    
    .PriceCategoryInput{
        width: 40em;
    }
    
    .MetaDescInput{
        height: 4em;
        width: 40em;
    }
    
    .ImageInput{
        width: 30em;
    }
    
    .ThumbnailInput{
        width: 30em;
    }
  }