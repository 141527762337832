.titleHolder {
  text-align: center;
  position: relative;
  padding: 0 0 20px;
  margin: 0 0 40px;
}

.titleHolder:after {
  transform: translateX(-50%);
  content: "";
  background: #1890ff;
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 3px;
  width: 50px;
}

.titleHolder h2 {
  font-size: 28px;
  margin: 0;
}

.block {
  padding: 60px 0;
}

.images {
  width: 240px;
}

.cardTitle {
  font-size: 1rem;
  text-align: center;
  padding-top: inherit;
  height: 70px;
}
.feature-card {
  cursor: pointer;
}

@media only screen and (min-width: 1180px) {
  .cardTitle {
    font-size: 0.7rem;
  }
}

@media only screen and (min-width: 1440px) {
  .cardTitle {
    font-size: 1rem;
  }
}
