.iconsCol {
  justify-content: center;
  margin-bottom: 20px;
}

.iconsP {
  justify-content: center;
}

.h3-icons {
  text-align: center;
  font-size: 1.2rem;
  padding-top: 20px;
}
.icons-title-div {
  height: 70px;
  margin-bottom: 10px;
}

.icons-div {
  text-align: center;
}

.img-icons {
  max-width: 120px;
  text-align: center;
}
