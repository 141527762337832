.h3-arak {
  padding-top: 30px;
  font-size: 1.2rem;
  padding-bottom: 5px;
  color: #0654b9;
  text-align: left;
}

.arakContainer {
  max-width: 1080px;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 90%;
  border: 1px solid #dddddd91;
}

td,
th {
  text-align: left;
  padding: 8px;
}


.arakCol {
  text-align: right;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #d6e7f3;
}
