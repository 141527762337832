.dashboard__widget-wrap {
  display: flex;
}

.dashboard__widget {
  background: linear-gradient(#ffffff, #69bcff);
  margin: 20px 20px;
  width: 400px;
  display: flex;
  flex-direction: column;
  height: 500px;
  padding: 12px;

  border-radius: 8px;
  border: 1px #000;
  border-style: solid;
}

.dashboard__widget-title {
  text-align: center;
  font-weight: 700;
  font-size: 1.2em;
}

.dashboard__widget-body {
  text-align: left;
  padding-left: 10px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

.dashboard__widget-body-span {
  font-size: 18px;
  font-weight: 500;

  color: rgb(59, 59, 59);
}

.dashboard__widget-body-span:hover {
  border-bottom: 2px solid #0276b9;
}

.links__widget {
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.links__widget:hover {
  transform: scale(1.1);
  transform: scale(1.1);
}

.dashboard__title-holder {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

.dashboard__h1-title {
  color: #000;
  text-align: center;
  font-size: 28px;
}

@media screen and (max-width: 960px) {
  .dashboard__widget-wrap {
    display: flex;
    flex-direction: column;
  }

  .dashboard__h1-title {
    font-size: 24px;
  }

  .dashboard__widget-title {
    text-align: center;
    font-weight: 600;
    font-size: 1.2em;
    padding-top: 10px;
  }

  .dashboard__widget {
    margin: 20px 20px;
    width: 80%;
    display: flex;
    flex-direction: column;
    height: 400px;
    align-items: center;
    border-radius: 8px;
    border: 1px #000;
    border-style: solid;
  }
}
