.NavBar {
  background: #ffffff;
  height: 80px;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem calc((100vw - 1000px) / 2);
  z-index: 10;
}

.NavLinkStyle {
  color: rgb(104, 104, 104);
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  font-weight: bold;
  cursor: pointer;
}

.NavLinkItem:hover {
  color: #628be2;
}

.active {
  color: #628be2;
}

.img-responsive {
  /* max-height: 80px; */
  display: block;
}

@media only screen and (max-width: 990px) {
  .img-responsive {
    height: 60px;
  }
}

.hambMenu {
  background-color: white;
}
