.contInputStyle{
    height: 2em;
    width: 15em;
}

.localDescInput{
    height: 4em;
    width: 15em;
}

@media only screen and (min-width: 760px) {
    .contInputStyle{
        width: 28em;
    }

    .localDescInput{
        height: 4em;
        width: 28em;
    }

}

@media only screen and (min-width: 1020px) {
    .contInputStyle{
        width: 28em;
    }

    .localDescInput{
        height: 4em;
        width: 40em;
    }

}