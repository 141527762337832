
  
  .TitleInput{
      width: 10em;
  }
  
  
  
  @media only screen and (min-width: 760px) {
  
      .TitleInput{
          width: 18em;
      }
      
    }
  
    @media only screen and (min-width: 1020px) {
  
  
      .TitleInput{
          width: 20em;
      }
      
     
    }
  
    @media only screen and (min-width: 1400px) {
    
  
      .TitleInput{
          width: 400em;
      }
    
    }