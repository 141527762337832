.box {
  background: #f9f9f9;
  height: auto;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem calc((100vw - 1000px) / 2);
  z-index: 10;
  position: relative;
  bottom: 0;
}

.footerItemKezelesek {
  color: rgb(104, 104, 104);
  cursor: pointer;
  text-decoration: none;
}

.footerItemKezelesek:hover {
  color: #628be2;
}

.footerTitle {
  color: #628be2;
  font-weight: 600;
  font-size: 1.1rem;
}

.footerLinks {
  cursor: pointer;
  text-decoration: none;
  color: rgb(104, 104, 104);
  text-align: left;
}

.listItemFooter {
  list-style: none;
}

.wrapper {
  display: grid;
  grid-template-columns: 100px 100px 100px;
}