.contact-box {
  max-width: 850px;
  display: block;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fff;
  margin: auto;

  /* box-shadow: 0px 0px 19px 5px rgba(0,0,0,0.19); */
}

.bookingText {
  color: rgb(104, 104, 104);
  font-size: 1.2rem;
  padding-top: 10px;
}

.field {
  max-width: 600px;
  width: 100%;
  border: 2px solid rgba(0, 0, 0, 0);
  outline: none;
  background-color: rgba(230, 230, 230, 0.6);
  padding: 0.5rem 1rem;
  font-size: 1.1rem;
  margin-bottom: 22px;
  transition: 0.3s;
}

.field:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

textarea {
  min-height: 150px;
}

.FormBtn {
  width: 50%;
  padding: 0.5rem 1rem;
  background-color: #628be2;
  color: #fff;
  font-size: 1.1rem;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  margin-bottom: 20px;
}

.FormBtn:hover {
  background-color: #4968aa;
}

.field:focus {
  border: 2px solid rgba(30, 85, 250, 0.47);
  background-color: #fff;
}

@media screen and (max-width: 880px) {
  .contact-box {
    grid-template-columns: 1fr;
  }
  .left {
    height: 200px;
  }
}

@media screen and (max-width: 1080px) {
  .contact-box {
    grid-template-columns: 1fr;
  }
  .left {
    height: 200px;
  }
  .FormBtn {
    width: 100%;
  }
}

textarea {
  resize: none;
}

.bookig-alert-div {
  text-align: center;
  margin-top: 15px;
  width: auto;
  max-width: 480px;
  padding: 10px 80px;
  font-size: 0.9rem;
}

.CallUsButton {
  padding: 15px 32px;
  background-color: #628be2;
  cursor: pointer;
  color: #fff;
  font-size: 0.8rem;
}

.CallUsButton:hover {
  background-color: #4968aa;
}

@media screen and (max-width: 325px) {
  .CallUsButton {
    font-size: 0.6rem;
  }
}

@media screen and (min-width: 750px) {
  .CallUsButton {
    font-size: 0.6rem;
  }
}

@media screen and (min-width: 1024px) {
  .CallUsButton {
    font-size: 1rem;
  }
}

@media screen and (min-width: 1440px) {
  .CallUsButton {
    font-size: 1.2rem;
  }
}
